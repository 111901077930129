import './Content.css'
import React, { ReactElement, useEffect } from 'react';
import ImageWrapper from '../components/ImageWrapper';
import { IMAGE_TEXTS } from '../config/imageTexts';

function importAll(r: any) {
    return r.keys().map(r);
}

interface ImageVariant {
    width: number;
    path: string;
}

interface Image {
    original: string;
    variants: ImageVariant[];
    imageText?: ReactElement
}

const images: Map<string, Image> = new Map();
function initializeImages() {
    const imagePaths: string[] = importAll(require.context('../../public/static/images/', false, /\.(png|jpe?g|svg)$/));
    imagePaths.forEach(path => {
        const pathParts = path.split('.');
        const parts = pathParts.length;
        const originalPath = pathParts
            .slice(0, parts - 2)
            .concat(pathParts[parts - 1])
            .join('.')
            .split('/');
        images.set(path.split('.')[0], { 
            original: path, 
            variants: [], 
            imageText: IMAGE_TEXTS.get(originalPath[originalPath.length - 1]) })
    });
    const compressedImpagePaths: string[] = importAll(require.context('../../public/static/images/compressed', false, /\.(png|jpe?g|svg)$/))
    compressedImpagePaths.forEach(path => {
        const strs = path.split('.')[0].split('_');
        const name = strs.slice(0, strs.length - 1).join('_');
        const width = strs[strs.length - 1];
        const image = images.get(name);
        if (image) {
            image.variants.push({ path, width: Number.parseInt(width) })
        }
    });
    images.forEach(image => image.variants.sort((a, b) => a.width - b.width))
}
initializeImages();

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const WorkPage: React.FunctionComponent<{}> = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            {
                shuffleArray(Array.from(images.values())).map((image) => {
                    const srcSet = image.variants
                        .map(variant => `${variant.path} ${variant.width}w`)
                        .join(', ')
                    return <ImageWrapper
                        key={image.original}
                        src={image.original}
                        srcSet={srcSet}
                        children={image.imageText} />
                })
            }
        </div>
    );
}

export default WorkPage;