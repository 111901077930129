import React, { ReactElement } from 'react';

interface ImageText {
    image: string;
    component: ReactElement;
}

const _IMAGE_TEXTS: ImageText[] = [
    {
        image: '1_Skate2019_1.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: '1_Skate2019_6.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: '1_Skate2019_3.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: '1_Skate2019_4.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: '1_Skate2019_2.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: 'skate__odenplan-043-022.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: 'skate__odenplan-044-019.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: 'skate__odenplan-044-030.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: 'Y2.jpg',
        component: <a href='https://www.nilslilja.com'>w/Nils Lilja</a>
    },
    {
        image: 'R3.jpg',
        component: <a href='https://syndicate.archi'>w/Syndicate</a>
    },
    {
        image: 'R1.jpg',
        component: <a href='https://syndicate.archi'>w/Syndicate</a>
    },
    {
        image: 'R2.jpg',
        component: <a href='https://syndicate.archi'>w/Syndicate</a>
    },
    {
        image: 'R4.jpg',
        component: <a href='https://syndicate.archi'>w/Syndicate</a>
    },
    {
        image: 'ROVIRATORRES_plates_helsinki02-01.jpg',
        component: <a href='https://salahars.com'>w/Sala Hars</a>
    },
    {
        image: 'ROVIRATORRES_plates_helsinki-01.jpg',
        component: <a href='https://salahars.com'>w/Sala Hars</a>
    },
    {
        image: 'PLATE-SIZIANO_2-01.jpg',
        component: <span>w/Charlotte Prins</span>
    },
    {
        image: 'PLATE-SIZIANO_interior-01.jpg',
        component: <span>w/Charlotte Prins</span>
    },
    {
        image: 'PLATE-SIZIANO-01.jpg',
        component: <span>w/Charlotte Prins</span>
    },
    {
        image: '20170315-frepau001851-01F.jpg',
        component: <a href='https://cargocollective.com/verapanichewskaja'>w/Vera Panichewskaja</a>
    },
]

export const IMAGE_TEXTS = new Map(_IMAGE_TEXTS.map(obj => [obj.image, obj.component]));
