import React, { FunctionComponent, useEffect } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import WorkPage from './pages/WorkPage';

// https://codesandbox.io/s/l9m3zrj4lq?from-embed=&file=/src/components/Container.js

const Container: FunctionComponent<{}> = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <Switch location={location}>
            <Route path='/about'>
                <AboutPage />
            </Route>
            <Route path='/contact'>
                <ContactPage />
            </Route>
            <Route path='/work'>
                <WorkPage />
            </Route>
            <Route path='**'>
                <Redirect to={'/work'} />
            </Route>
        </Switch>
    )
}

export default Container;