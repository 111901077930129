import './ImageWrapper.css'
import React, { ImgHTMLAttributes, useState } from 'react';

interface ImageWrapper extends ImgHTMLAttributes<HTMLImageElement> { }

const ImageWrapper: React.FunctionComponent<ImageWrapper> = ({ children, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <div className='image-wrapper'>
            <img alt={''} {...props} onLoad={() => setLoaded(true)} />
            { loaded && <div className='image-text'>{children}</div>}
        </div>
    );
}

export default ImageWrapper;