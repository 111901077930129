import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css'

const Header: React.FunctionComponent<{}> = () => {
    const location = useLocation();
    const linkNames = ['work', 'about', 'contact']
    const links = linkNames.map((name) =>
        <Link
            key={name}
            className={location.pathname.toLowerCase().includes(name) ? 'active' : ''}
            to={name}
        >
            {name}
        </Link>
    )
    return (
        <header>
            <div>Marcelo Rovira Torres</div>
            <div className={'links'}>
                {links}
            </div>
        </header>
    )
}

export default Header;