import './Content.css'
import React, { useEffect } from 'react';

const AboutPage: React.FunctionComponent<{}> = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <section>
                <p>
                    Marcelo Rovira Torres is a Swedish architect currently based in Stockholm.
                    He studied at The Royal Institute of Technology (KTH), 
                    ETH-Zürich and holds a masters degree in architecture from the EPF-Lausanne.
                    Marcelo currently teaches architecture at the master's programme of the faculty 
                    of architecture at The Royal Institute of Technology in Stockholm. 
                </p>
            </section>
            <section>
                <span>Grants and Awards</span>
                <ul>
                    <li>Grant Anders Mortner’s fund, Swedish Royal Academy of Fine Arts, 06.2023</li>
                    <li>Nominee Design S Interior Architecture, 10.2020</li>
                    <li>Winner Ung Svensk Form, 02.2020</li>
                    <li>Årets Utställning Forum för utställare, 02.2020</li>
                    <li>Grant Anders Mortner’s fund, Swedish Royal Academy of Fine Arts, 05.2019</li>
                    <li>Grant Ivar {'&'} Anders Tengbom’s fund, Swedish Royal Academy of Fine Arts, 05.2018</li>
                    <li>Grant Ingeborg Hermelin’s fund Swedish Classic Association 05.2009</li>
                </ul>
            </section>
            <section>
                <span>Publications</span>
                <ul>
                
                    <li>The ‘Analogue City’,Urban Planning - Cogitatio Press, Lisbon, Portugal, 09.2020</li>
                    <li>San Rocco no.15 Muerte, Milan, Italy, 06.2019</li>
                    <li>Nationalmuseum; A New Collection, Stockholm, Sweden, 11.2018</li>
                    <li>Pnyx of AA School no. 46, London, UK, 10.2018</li>
                    <li>L’Atelier Magazine no.15, Lausanne, Switzerland, 04.2018</li>
                    <li>Hochparterre, Zürich, Switzerland, 08.2017</li>
                    <li>L’Atelier Magazine no. 13, Lausanne, Switzerland, 04.2017</li>
                    <li>Trouble With Classicist, Lausanne, Switzerland, 02.2017</li>
                    <li>Trans Magazine no. 30, Zürich, Switzerland, 02.2017</li>
                    <li>Index Newspaper, Porto, Portugal, 07.2016</li>
                </ul>
            </section>
            <section>
                <span>Lectures</span>
                <ul>
                    <li>Arkitektur- och designcentrum Stockholm, 12.2022</li>
                    <li>International Colloquium - Architectural Competitions in Europe Paris, 11.2022</li>
                    <li>Chalmers university of technology, 11.2021</li>
                    <li>Technischen Universität Wien, 04.2021</li>
                    <li>Malmstens Linköping University, 10.2020</li>
                    <li>KTH Royal Institute of Technology, 04.2020</li>
                    <li>KTH Royal Institute of Technology, 02.2020</li>
                    <li>Beckmans College of Design, 12.2019</li>
                    <li>Malmstens Linköping University, 10.2019</li>
                    <li>University of Antwerp, 02.2019</li>
                    <li>Beckmans College of Design, 11. 2018</li>
                    <li>KTH Royal Institute of Technology, 10.2018</li>
                </ul>
            </section>
            <section>
                <span>Exhibitions</span>
                <ul>
                    <li>Röhsska Museet, Gothenburg, Sweden 10.2020-11.2020</li>
                    <li>Form {'&'} Design Centrum, Malmö, Sweden, 07.2020 - 08.2020</li>
                    <li>IKEA Museum, Älmhult, Sweden, 04.2020 - 06.2020</li>
                    <li>Arkdes Architecture {'&'} Design museum, Stockholm, Sweden, 02.2020 - 03.2020</li>
                    <li>Regionmuseet Skåne, Kristianstad, Sweden 05.2019 - 08.2019</li>
                    <li>Kunstmuseum Bern, Bern, Switzerland, 09.2018 - 11.2018</li>
                    <li>WoodStockholm Summer Design Week, Stockholm, Sweden, 08.2018</li>
                    <li>Etage Projects  Stockholm Furnitre Fair, Stockholm, Sweden, 02.2017</li>
                    <li>Kulturhuset Stadsteatern, Stockholm, Sweden 10.2015</li>
                </ul>
            </section>
        </div>
    );
}

export default AboutPage;
