import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Container from './Container';
import Header from './Header';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Container />
      </BrowserRouter>
    </div >
  );
}

export default App;
